<template>
    <v-main>
        <div
            class="cookie-notification"
            v-if="cookieShow"
        >
            <p>
                Этот сайт использует cookie и другие технологии для сбора информации технического характера. Подробнее о
                <router-link :to="{name: 'Cookies'}" target="_blank">
                    файлах cookie
                </router-link>
            </p>
            <v-btn
                color="gray"
                class="cookie-accept"
                @click="cookieAccept"
            >
                Хорошо
            </v-btn>
        </div>
        <section id="hero">
            <v-row no-gutters>
                <v-container
                    id="leedbot"
                    tag="section"
                    class="leedbot"
                >
                    <v-row
                        justify="center"
                    >
                        <v-col
                            cols="12"
                            xl="6"
                            lg="8"
                            md="8"
                            sm="10"
                            style="padding-top: 5%"
                            class="main-col"
                        >
                            <v-card
                                class="v-card-profile v-card--material pa-3"
                            >
                                <div class="d-flex grow flex-wrap">
                                    <div
                                        class="v-avatar mx-auto v-card--material__avatar elevation-6 grey"
                                        style="height: 128px; min-width: 128px; width: 128px"
                                    >
                                        <div class="v-image v-responsive theme--light">
                                            <img v-if="userData.avatar" :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"
                                                 alt=""
                                            >
                                            <div class="v-responsive__content" style="width: 250px"></div>
                                        </div>
                                    </div>

                                    <v-card-text class="text-center">
                                        <h4 class="display-2 font-weight-light mb-3 black--text">
                                            {{ userData.name }} {{ userData.last_name }}
                                        </h4>

                                        <h6
                                            class="display-1 mb-3 green--text"
                                            v-if="userData.who_is"
                                        >
                                            <v-icon class="mr-2 green--text">mdi-seal-variant</v-icon>
                                            {{ userData.who_is }}
                                        </h6>
                                    </v-card-text>
                                </div>
                                <v-divider/>
                                <v-row>
                                    <v-col class="">
                                        <div class="chat-list">
                                            <div>
                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesFirst"
                                                    >
                                                        <div class="chat-avatar">
                                                            <img v-if="userData.avatar" :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"
                                                                 alt=""
                                                            >
                                                        </div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime1 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p>
                                                                        Привет, на связи {{ userData.name }} {{ userData.last_name }}!
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesSecond"
                                                    >
                                                        <div class="chat-avatar">
                                                            <img v-if="userData.avatar" :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"
                                                                 alt=""
                                                            >
                                                        </div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime1 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p class="mb-5">
                                                                        Сейчас я расскажу вам о том, как сохранить и укрепить свое здоровье.
                                                                    </p>
                                                                    <p>
                                                                        Для начала посмотрите видео "10 правил, которые помогут жить дольше и чувствовать себя превосходно".
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesReq1"
                                                    >
                                                        <div class="chat-avatar">
                                                            <img v-if="userData.avatar" :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"
                                                                 alt=""
                                                            >
                                                        </div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime1 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message" style="width: 100%;">
                                                                    <div class="thumb-wrap">
                                                                        <iframe
                                                                            width="560"
                                                                            height="315"
                                                                            src="https://www.youtube.com/embed/tbwaJ0HJggs"
                                                                            title="10 правил, которые позволят жить дольше и чувствовать себя превосходно"
                                                                            frameborder="0"
                                                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                                            allowfullscreen
                                                                        ></iframe>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <transition name="fade">
                                                                <div
                                                                    v-if="btnDalee1"
                                                                    class="chat-controls"
                                                                >
                                                                    <v-btn
                                                                        class="chat-btn"
                                                                        :disabled="disBtnReq1"
                                                                        @click="btnReq1"
                                                                    >
                                                                        <v-icon class="mr-3">mdi-send</v-icon>
                                                                        Далее
                                                                    </v-btn>
                                                                </div>
                                                            </transition>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesReq2"
                                                    >
                                                        <div class="chat-avatar">
                                                            <img v-if="userData.avatar" :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"
                                                                 alt=""
                                                            >
                                                        </div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime2 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p class="mb-5">
                                                                        Большинство проблем со здоровьем - это результат неправильного питания и образа жизни.
                                                                    </p>
                                                                    <p>
                                                                        На основе результатов онлайн-теста, выберите систему организма в меню ниже
                                                                        и получите рекомендации по ее улучшению.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        v-if="mesReq3"
                                                    >
                                                        <v-expansion-panels
                                                            popout
                                                            focusable
                                                            class="exp-panel-title"
                                                        >
                                                            <v-expansion-panel>
                                                                <v-expansion-panel-header>
                                                                    Пищеварительная система
                                                                </v-expansion-panel-header>
                                                                <v-expansion-panel-content>
                                                                    <p class="mt-10" style="width: 100%; max-width: 450px; height: auto">
                                                                        <img style="width: 100%" src="/img/health/gkt.jpeg" alt=""/>
                                                                    </p>
                                                                    <p class="mt-10">
                                                                        <strong>Заболевания желудочно-кишечного тракта</strong> возникают из-за неправильного питания,
                                                                        использования лекарственных препаратов (антибиотиков, обезболивающих и
                                                                        противовоспалительных препаратов), отсутствия в рационе клетчатки, перебора
                                                                        с сахарами и мучной едой, нарушения питьевого режима, стрессов, приема токсичной еды и тд.
                                                                    </p>
                                                                    <p>
                                                                        Заболевания желудочно-кишечного тракта объединяют в себе заболевания различных органов, таких как:
                                                                        пищевод, желудок, толстая и тонкая кишка, желчный пузырь, печень и поджелудочная железа.
                                                                        Типичные признаки патологий ЖКТ: изжога, метеоризм (вздутие, вызванное скоплением газов в кишечнике),
                                                                        отрыжка, тошнота и рвота, проблемы со стулом (запор или диарея), неприятный запах изо рта, появление налета на языке.
                                                                    </p>
                                                                    <p>
                                                                        <strong>
                                                                        Для восстановления организма при заболеваниях желудочно-кишечного тракта важно:
                                                                        </strong>
                                                                    </p>
                                                                    <ul>
                                                                        <li>
                                                                            Соблюдать баланс в питании, физических и психоэмоциональных нагрузках.
                                                                        </li>
                                                                        <li>
                                                                            Восполнить дефицит макро и микроэлементов: цинка, калия, магния, селена, железа, молибдена, витаминов А, В, С, D, Е, полиненасыщенных жирных кислот Омега-3, качественного белка.
                                                                        </li>
                                                                        <li>
                                                                            Необходимо исключить из рациона копчености, соления и острые блюда, жареную, маринованную и консервированную пищу, острые овощи (перец чили, редька, редиска, лук, чеснок), пряности (хрен, горчицу),  бобовые, а также кондитерские изделия, сдобу, шоколад, соусные заправки и жирные сыры, крепкий чай и кофе, газированные и алкогольные напитки.
                                                                        </li>
                                                                        <li>
                                                                            Рекомендовано включить в рацион  гречневую, овсяную кашу, ячмень,  сою, нежирные сорта мяса (индейка, телятина, говядина), отварную рыбу, яйца, овощи тушеные и отварные, некислые ягоды и фрукты в небольшом  количестве, компоты из сухофруктов и кисели, травяные чаи, отвар шиповника, кисломолочные продукты.
                                                                        </li>
                                                                        <li>
                                                                            Важно соблюдать режим питания. Перерывы между приемами пищи не должны превышать 4 часа. Огромное значение специалисты отводят способу приготовления блюд и рекомендуют пищу отваривать, тушить, готовить на пару.
                                                                        </li>
                                                                    </ul>
                                                                    <p>
                                                                        <strong>Питьевой режим очень важен для процесса восстановления.</strong>
                                                                        Вода – это растворитель и транспорт для всех питательных веществ, поступающих в каждую клетку, и продуктов распада,
                                                                        выводимых из клетки. Рекомендуется употреблять только проточную фильтрованную воду, желательно температуры не ниже
                                                                        20 градусов. Черный чай, кофе, соки в объем выпитой жидкости не входит. Рекомендовано пить травяные чаи, отвары, компоты.
                                                                    </p>
                                                                    <p>
                                                                        <strong>Придерживайтесь умеренных физических нагрузок</strong>
                                                                        – ходьба классическая, ходьба скандинавская, прогулки на свежем воздухе.
                                                                    </p>
                                                                    <p>
                                                                        <strong>Отведите достаточное время на сон</strong>
                                                                        – засыпайте не позднее 23:00 и просыпайтесь не раньше 5:30. И не забудьте придерживаться рекомендаций лечащего врача.
                                                                    </p>
                                                                </v-expansion-panel-content>
                                                            </v-expansion-panel>
                                                            <v-expansion-panel>
                                                                <v-expansion-panel-header>
                                                                    Сердечно-сосудистая система
                                                                </v-expansion-panel-header>
                                                                <v-expansion-panel-content>
                                                                    <p class="mt-10" style="width: 100%; max-width: 450px; height: auto">
                                                                        <img style="width: 100%" src="/img/health/heart.jpeg" alt=""/>
                                                                    </p>
                                                                    <p class="mt-10">
                                                                        <strong>Заболевания сердечно-сосудистой системы</strong> возникают из-за неправильного
                                                                        питания с избытком трансжиров, простых углеводов, сахара, мучного, нерегулярных приемов
                                                                        пищи, а особенно пропущенного завтрака.
                                                                    </p>
                                                                    <p>
                                                                        Отсутствие белка, витаминов А, Е, В, С, микроэлементов, Омега-3 приводит к накоплению висцерального жира, истончению стенок сосудов, образованию холестериновых бляшек, закупорке капилляров, нарушению работы сердца из-за слабости сердечной мышцы и клапанов.
                                                                    </p>
                                                                    <p>Таким образом, заболевания сердечно-сосудистой системы развиваются в результате нарушения работы ряда систем организма и ведут к стойкому повышению или снижению артериального давления, функциональным и органическим изменениям сердца, центральной нервной системы и почек.</p>
                                                                    <p>Считается, что после 40 лет каждый человек входит в группу риска по ишемическим болезням сердца, в связи с чем рекомендовано проводить профилактическое лечение. В реальности подобные заболевания могут развивать в значительно более раннем возрасте. Причина лежит в неправильном питании, вредных привычках, образе жизни и экологии.</p>
                                                                    <p>Наиболее распространенные заболевания - это артериальная гипертензия, которая проявляется первыми симптомами -  головной болью, шумом в ушах. Часто при данных симптомах принимают обезболивающие препараты, что недопустимо, так как это приводит к дальнейшему ухудшению заболевания.</p>
                                                                    <p>Однако более серьезное заболевание - это ишемическая болезнь сердца (стенокардия, инфаркт), которое может привести к инвалидности или летальному исходу.</p>
                                                                    <p><strong>Профилактика сердечно-сосудистых заболеваний и их раннее лечение играют огромную роль в повышении качества жизни людей.</strong></p>
                                                                    <p>
                                                                        <strong>
                                                                            Для восстановления организма при заболеваниях сердечно-сосудистой системы важно:
                                                                        </strong>
                                                                    </p>
                                                                    <ul>
                                                                        <li>
                                                                            Соблюдать баланс в питании, физических и психоэмоциональных нагрузках.
                                                                        </li>
                                                                        <li>
                                                                            Восполнить дефицит макро и микроэлементов: цинка, калия, магния, селена,
                                                                            железа, молибдена, витаминов А, В, С, D, Е, полиненасыщенных жирных кислот Омега-3, качественного белка, клетчатки.
                                                                        </li>
                                                                        <li>
                                                                            Необходимо исключить из рациона копчености, соления и острые блюда, полуфабрикаты, колбасы, жареную, маринованную и консервированную пищу, маргарин и спреды,  а также кондитерские изделия, молочный шоколад, соусные заправки и сыры, крепкий чай и кофе, газированные и алкогольные напитки.  Ограничивается употребление соли. Рекомендуется избегать изделий из белой муки.
                                                                        </li>
                                                                        <li>
                                                                            Рекомендовано включить в рацион цельнозерновой хлеб и  цельнозерновые  макароны, гречневую, пшенную, овсяную кашу, ячмень, темный рис, фасоль, сою, орехи, сухофрукты, нежирные сорта мяса (индейка, телятина, говядина), отварную рыбу,  морепродукты,  яйца, овощи в сыром и приготовленном виде, ягоды и фрукты, компоты из сухофруктов и кисели, травяные чаи, отвар шиповника, кисломолочные продукты, продукты, богатые калием: курага, бананы, печеный картофель (в кожуре), томаты; Красное мясо лучше заменить на красную рыбу.
                                                                        </li>
                                                                        <li>
                                                                            Огромное значение специалисты отводят способу приготовления блюд и рекомендуют пациентам с заболеваниями сердечно-сосудистыми заболеваниями вводить в рацион ягоды, фрукты и овощи в свежем виде, оливковое масло холодного отжима, авокадо, хлеб грубого помола или отрубной, яйца, жирные сорта рыбы.  При этом овощи подвергают минимальной термической обработке.  Солить лучше готовую пищу и использовать подсоленные смеси ароматических трав. В процессе приготовления пищи максимально убирать жир. 
                                                                        </li>
                                                                    </ul>
                                                                    <p>Питание должно быть дробным  5-6 раз в сутки.  Важно не переедать!</p>  
                                                                    <p>
                                                                        <strong>Питьевой режим очень важен для процесса восстановления.</strong>
                                                                        Вода – это растворитель и транспорт для всех питательных веществ, поступающих в каждую клетку, и продуктов распада,
                                                                        выводимых из клетки. Рекомендуется употреблять только проточную фильтрованную воду, желательно температуры не ниже
                                                                        20 градусов. Черный чай, кофе, соки в объем выпитой жидкости не входит. Рекомендовано пить травяные чаи, отвары, компоты.
                                                                    </p>
                                                                    <p>
                                                                        <strong>Придерживайтесь умеренных физических нагрузок</strong>
                                                                        – ходьба классическая, ходьба скандинавская, прогулки на свежем воздухе.
                                                                    </p>
                                                                    <p>Упражнения с различного рода отягощениями - гирями, гантелями, а также подтягивания, отжимания, жимы, приседания со штангой, планки, тяги для сердца вредны.</p>
                                                                    <p>
                                                                        <strong>Отведите достаточное время на сон</strong>
                                                                        – засыпайте не позднее 23:00 и просыпайтесь не раньше 5:30. И не забудьте придерживаться рекомендаций лечащего врача.
                                                                    </p>
                                                                </v-expansion-panel-content>
                                                            </v-expansion-panel>
                                                            <v-expansion-panel>
                                                                <v-expansion-panel-header>
                                                                    Нервная система
                                                                </v-expansion-panel-header>
                                                                <v-expansion-panel-content>
                                                                    <p class="mt-10" style="width: 100%; max-width: 450px; height: auto">
                                                                        <img style="width: 100%" src="/img/health/nerv.jpeg" alt=""/>
                                                                    </p>
                                                                    <p class="mt-10">
                                                                        <strong>Основными и более частыми причинами заболеваний нервной системы являются:</strong>
                                                                    </p>
                                                                    <ul>
                                                                        <li>нарушения кровообращения головного мозга;</li>
                                                                        <li>хроническая гипоксия;</li>
                                                                        <li>сильные и хронические стрессы;</li>
                                                                        <li>гормональные нарушения по разным причинам, включая прием противовоспалительных препаратов;</li>
                                                                        <li>врожденные патологии;</li>
                                                                        <li>нарушение обменных процессов;</li>
                                                                        <li>болезни желудочно-кишечного тракта и нарушения микрофлоры;</li>
                                                                        <li>вирусные и инфекционные заболевания;</li>
                                                                        <li>онкология.</li>
                                                                    </ul>
                                                                    <p>
                                                                        Некачественное питание и употребление в пищу большого количества рафинированных продуктов, сахаров и консервантов также способствует развитию заболеваний.
                                                                    </p>
                                                                    <p>
                                                                        <strong>
                                                                            Для восстановления нервной системы важно:
                                                                        </strong>
                                                                    </p>
                                                                    <ul>
                                                                        <li>
                                                                            Соблюдать баланс в питании, физических и психоэмоциональных нагрузках.
                                                                        </li>
                                                                        <li>
                                                                            Восполнить дефицит макро и микроэлементов, в первую очередь Магния, кальция, фосфора, меди, железа,  витаминов группы В, С, Е,  полиненасыщенных жирных кислот Омега-3, качественного белка, клетчатки.
                                                                        </li>
                                                                        <li>
                                                                            Необходимо исключить из рациона копчености, соления и острые блюда, жареную, маринованную и консервированную пищу,  тяжелую для переваривания пищу.  При нарушениях работы нервной системы ограничиваются овощи и фрукты с грубой клетчаткой.  А также кондитерские изделия, шоколад,  крепкий чай и кофе, газированные и алкогольные напитки.
                                                                        </li>
                                                                        <li>
                                                                            Рекомендовано включить в рацион скумбрию, треску, лосось. В 60 раз снижают риск развития депрессии!  Полезны субпродукты - печень, сердце, морепродукты.  Обязательно яйца, зелень, цельнозерновые продукты. Сыр, картофель, неочищенный рис, соя, арахис, кунжут содержат полезные аминокислоты.  Клубника - ягода хорошего настроения. Бананы и томаты снижают риск депрессий.  Очень полезны для нервной системы молоко и кисломолочные продукты.
                                                                        </li>
                                                                        <li>
                                                                            Еда должна поступать в организм своевременно, поэтому нельзя пропускать завтраки, обеды, ужины и перекусы. Необходимо соблюдать калорийность в зависимости от возраста, пола, веса и рода деятельности. 
                                                                        </li>
                                                                    </ul>
                                                                    <p>
                                                                        <strong>Питьевой режим очень важен для процесса восстановления.</strong>
                                                                        Вода – это растворитель и транспорт для всех питательных веществ, поступающих в каждую клетку, и продуктов распада,
                                                                        выводимых из клетки. Рекомендуется употреблять только проточную фильтрованную воду, желательно температуры не ниже
                                                                        20 градусов. Черный чай, кофе, соки в объем выпитой жидкости не входит. Рекомендовано пить травяные чаи, отвары, компоты.
                                                                    </p>
                                                                    <p>
                                                                        <strong>Придерживайтесь умеренных физических нагрузок</strong>
                                                                        – прогулки на свежем воздухе, водные процедуры, контрастный душ, закаливание.
                                                                    </p>
                                                                    <p>
                                                                        <strong>Отведите достаточное время на сон</strong>
                                                                        – засыпайте не позднее 23:00 и просыпайтесь не раньше 5:30. И не забудьте придерживаться рекомендаций лечащего врача.
                                                                    </p>
                                                                </v-expansion-panel-content>
                                                            </v-expansion-panel>
                                                            <v-expansion-panel>
                                                                <v-expansion-panel-header>
                                                                    Опорно-двигательная система
                                                                </v-expansion-panel-header>
                                                                <v-expansion-panel-content>
                                                                    <p class="mt-10" style="width: 100%; max-width: 450px; height: auto">
                                                                        <img style="width: 100%" src="/img/health/opdvig.jpeg" alt=""/>
                                                                    </p>
                                                                    <p class="mt-10">
                                                                        <strong>Заболевания опорно-двигательной системы возникают по следующим причинам:</strong>
                                                                    </p>
                                                                    <ul>
                                                                        <li>чрезмерная нагрузка на позвоночник и суставы (тяжелая физическая работа, профессиональный спорт);</li>
                                                                        <li>резкое поднятие тяжестей;</li>
                                                                        <li>недостаточная активность (сидячая работа);</li>
                                                                        <li>избыточный вес;</li>
                                                                        <li>дефицит кальция, магния, фосфора;</li>
                                                                        <li>дефицит марганца;</li>
                                                                        <li>дефицит белка и витаминов А, Е, С;</li>
                                                                        <li>стрессы;</li>
                                                                        <li>закисление организма (кости, зубы, волосы - щелочной резерв организма, который начинает растворяться);</li>
                                                                        <li>возрастные гормональные изменения (период менопаузы и после нее);</li>
                                                                        <li>травмы;</li>
                                                                        <li>эндокринные, инфекционные и аутоиммунные заболевания.</li>
                                                                    </ul>
                                                                    <p>
                                                                        <strong>
                                                                            Для восстановления опорно-двигательной системы важно:
                                                                        </strong>
                                                                    </p>
                                                                    <ul>
                                                                        <li>восполнить дефицит витаминов, белка, микроэлементов;</li>
                                                                        <li>снизить кислотность;</li>
                                                                        <li>привести физические нагрузки к оптимальному уровню (например, заняться скандинавской ходьбой или плаванием).</li>
                                                                        <li>в рацион необходимо ввести продукты, богатые Са: кунжут, орехи, сардины, соевые бобы и сельдерей.</li>
                                                                        <li>для снижения кислотности кушать больше овощей и пить чистую воду.</li>
                                                                        <li>для получения коллагена необходимо употреблять в пищу домашний холодец или желе.</li>
                                                                    </ul>
                                                                    <p>
                                                                        <strong>Питьевой режим очень важен для процесса восстановления.</strong>
                                                                        Вода – это растворитель и транспорт для всех питательных веществ, поступающих в каждую клетку, и продуктов распада,
                                                                        выводимых из клетки. Рекомендуется употреблять только проточную фильтрованную воду, желательно температуры не ниже
                                                                        20 градусов. Черный чай, кофе, соки в объем выпитой жидкости не входит. Рекомендовано пить травяные чаи, отвары, компоты.
                                                                    </p>
                                                                    <p>
                                                                        <strong>Придерживайтесь умеренных физических нагрузок</strong>
                                                                        – ходьба классическая, ходьба скандинавская, прогулки на свежем воздухе.
                                                                    </p>
                                                                    <p>
                                                                        <strong>Отведите достаточное время на сон</strong>
                                                                        – засыпайте не позднее 23:00 и просыпайтесь не раньше 5:30. И не забудьте придерживаться рекомендаций лечащего врача.
                                                                    </p>
                                                                </v-expansion-panel-content>
                                                            </v-expansion-panel>
                                                            <v-expansion-panel>
                                                                <v-expansion-panel-header>
                                                                    Иммунная система
                                                                </v-expansion-panel-header>
                                                                <v-expansion-panel-content>
                                                                    <p class="mt-10" style="width: 100%; max-width: 450px; height: auto">
                                                                        <img style="width: 100%" src="/img/health/immun.jpeg" alt=""/>
                                                                    </p>
                                                                    <p class="mt-10">
                                                                        <strong>Иммунные болезни принято делить на три группы по типу нарушения:</strong>
                                                                    </p>
                                                                    <ul>
                                                                        <li>недостаточное функционирование (иммунодефициты);</li>
                                                                        <li>чрезмерное функционирование (аллергии);</li>
                                                                        <li>атипичное функционирование (аутоиммунные болезни).</li>
                                                                    </ul>
                                                                    <p>
                                                                        Болезни иммунной системы, при которых развивается состояние иммунодефицита, могут быть:
                                                                    </p>
                                                                    <p>
                                                                        <strong>а) первичными – врожденными или наследственными</strong> (результат сбоев работы отдельных генов, вызванных наследственными факторами). В случаях врожденного иммунодефицита патология часто диагностируется уже в раннем возрасте, иногда в первые недели жизни. У ребенка задерживается физическое развитие организма, и легко возникают различные тяжелые заболевания.
                                                                    </p>
                                                                    <p><strong>) вторичными – приобретенными.</strong> Вторичные иммунодефициты могут развиваться на фоне перенесенных инфекционных, интоксикации, облучения, приема лекарственных препаратов (антибиотиков, иммунодепрессантов).</p>
                                                                    <p><strong>Аллергия</strong> - самое распространенное из всех нарушений работы иммунной системы. Точная природа аллергии не выяснена, но среди провоцирующих факторов врачи называют следующие:</p>
                                                                    <ul>
                                                                        <li>Наследственность</li>
                                                                        <li>Длительный контакт с потенциально аллергенными компонентами</li>
                                                                        <li>Нарушения работ ЖКТ</li>
                                                                        <li>Стрессы</li>
                                                                        <li>Пониженный иммунитет</li>
                                                                    </ul>
                                                                    <p>Часто аллергия проявляется в детском возрасте, но при неблагоприятных условиях аллергия может развиваться и у взрослых людей.</p>
                                                                    <p><strong>Аутоиммунные заболевания</strong>. Причины развития аутоиммунных заболеваний часто остаются невыясненными. Врачи отмечают, что в некоторых случаях они могут возникать на фоне онкологии или длительных воспалительных процессов (часто вызванных вирусом).</p>
                                                                    <p>
                                                                        <strong>
                                                                            Восстановление иммунной системы
                                                                        </strong>
                                                                    </p>
                                                                    <p>Прежде всего нужно четко понимать, что в повседневной жизни каждого современного человека имеется ряд факторов, влияние которых мы даже не замечаем, но они каждый день изнутри «подтачивают» ресурсы нашей иммунной системы, в результате чего организм становится все более беззащитным перед многомиллионной армией вирусов и бактерий. Поэтому одним из основных мероприятий по восстановлению иммунной системы являются меры по устранению (или хотя бы минимизации) контакта организма с этими факторами.</p>
                                                                    <p>Какие это факторы?</p>
                                                                    <p><strong>Токсины</strong>. В наш организм вместе с воздухом, пищей и водой ежедневно попадают соли тяжелых металлов (кадмий, ртуть, свинец и др). А кадмий при этом еще и препятствует усвоению цинка – одного из основных элементов, который нужен организму для противостояния болезнетворным бактериям.</p>
                                                                    <p><strong>Электромагнитные излучения</strong> - их источники окружают нас повсюду. Это лампы «дневного света», различные источники УВЧ-излучений, высоковольтные линии электропередачи. Электромагнитные поля негативно влияют на работу некоторых желез, нарушают функции мозга и подрывают иммунитет. Помимо этого они негативно воздействуют на нервную систему, формируют ощущение стресса, который также способствует ослаблению иммунитета.</p>
                                                                    <p><strong>Гиподинамия и сидячий образ жизни.</strong> Малоподвижный образ жизни приводит к уменьшению количества кислорода в крови, нарушается ее циркуляция. Таким образом создаются все условия для снижения иммунитета и возникновения хронических заболеваний.</p>
                                                                    <p><strong>Закисление организма (ацидоз)</strong>: преобладание в рационе большого количества продуктов, закисляющих организм (жирное и красное мясо; молочные продукты (кроме фермерских); выпечка и прочие дрожжевые изделия; кукурузное масло; зерновые крупы; сахар любого рода; кофе, чай; пиво, слабоалкогольные коктейли; пакетированные соки, газировки и т.д.). И, соответственно, дефицит ощелачивающих продуктов.</p>
                                                                    <p>Это далеко не полный список того, что подрывает иммунитет, делает нас уязвимыми для серьезных заболеваний.</p>
                                                                    <p><strong>Сюда следует добавить:</strong> стрессы, регулярные недосыпания, хроническую усталость, избыточный вес, несбалансированное питание, бесконтрольный прием антибиотиков и некоторых других лекарств, нарушение микрофлоры кишечника (дисбактериоз), алкоголь и курение.</p>
                                                                    <p>Лекарственную терапию мы затрагивать не будет, ее вам назначит врач, а мы расскажем, как укрепить иммунитет с помощью соблюдения 5 принципов:</p>
                                                                    <p><strong>1. Правильное сбалансированное питание</strong>: полноценные белки,  правильные жиры,  сложные углеводы, клетчатка, полиненасыщенные жирные кислоты, витамины (А, С, Е, D, вся группа витаминов В, β-каротин), минеральные соли, макро- и микроэлементы (цинк, селен, железо, медь и др.)</p>
                                                                    <p><strong>2. Физическая активность</strong>:  все виды аэробных физических упражнений: плавание, гимнастика, аэробика, бег, прогулки и др. Главное в физических упражнениях – не переусердствовать: только умеренная физическая нагрузка стимулирует иммунитет.</p>
                                                                    <p><strong>3. Рациональный режим сна и бодрствования</strong>: во время ночного сна секретируются факторы иммунной защиты. Благодаря им мобилизуется иммунная система для отражения вирусной или бактериальной атаки.</p>
                                                                    <p><strong>4. Водные процедуры и закаливание</strong>: обливание прохладной водой, контрастный душ и перепад температур закаляют организм, делают его более устойчивым к холоду, а, следовательно, к простудным заболеваниям.</p>
                                                                    <p><strong>5. Иммуномодуляторы</strong>.  Современная медицина считает максимально оправданным использование природных адаптогенов и иммуномодуляторов, действия которых сводится к тому, что они активируют клеточный и гуморальный иммунитет. Происходит регуляция механизмов иммунной системы естественным путем, повышается выработка антител, и это приводит к комплексной защите от вирусов и бактерий.</p>
                                                                    <p><strong>Укрепляющие иммунитет</strong> вещества содержатся в мёде, чесноке, лимоне,  клюкве, корне имбиря и других натуральных продуктах. Настои плодов шиповника, сок черной смородины, облепихи, земляники, рябины, калины, клюквы, брусники, черники. Отвары из шиповника, липы, калины, малины, настои на имбире, отвары листьев крапивы также содержат биологически активные вещества, помогающие в домашних условиях укрепить иммунитет. Одна ложка меда в день тоже поможет иммунитету.</p>
                                                                    <p>Заботиться об иммунитете нужно постоянно, и если у человека недостаточно времени на приготовление отваров или на регулярное обливание, либо присутствуют противопоказания, можно выбрать другую систему укрепления иммунитета.</p>
                                                                </v-expansion-panel-content>
                                                            </v-expansion-panel>
                                                            <v-expansion-panel>
                                                                <v-expansion-panel-header>
                                                                    Эндокринная система
                                                                </v-expansion-panel-header>
                                                                <v-expansion-panel-content>
                                                                    <p class="mt-10" style="width: 100%; max-width: 450px; height: auto">
                                                                        <img style="width: 100%" src="/img/health/endo.jpeg" alt=""/>
                                                                    </p>
                                                                    <p class="mt-10">
                                                                        <strong>Эндокринные заболевания</strong>
                                                                    </p>
                                                                    <ul>
                                                                        <li>возникают на фоне стрессов;</li>
                                                                        <li>несбалансированного питания;</li>
                                                                        <li>неблагополучной экологической обстановки;</li>
                                                                        <li>отягощаются наследственностью.</li>
                                                                    </ul>
                                                                    <p>
                                                                        Гормоны управляют важнейшими процессами, происходящими в организме: ростом, созреванием, репродукцией, обменом веществ, правильным функционированием органов и систем. Болезни эндокринной системы, как правило, носят длительный характер, а со временем могут вызывать тяжелые недуги, поэтому важно вовремя предотвратить их возникновение, выявить как можно раньше или предупредить развитие их осложнений.
                                                                    </p>
                                                                    <p>Важно понять, что гормоны имеют регуляторную функцию. В отличие от витаминов, аминокислот, полисахаридов и др., непосредственного участия в биохимических процессах они не принимают.</p>
                                                                    <p>Гормон – это команда.</p>
                                                                    <p>К примеру: при сахарном диабете функция организма по расщеплению глюкозы не нарушена, но по причине отсутствия или дефекта инсулина команды по утилизации глюкозы нет. Чаще всего говоря об эндокринных заболеваниях, мы подразумеваем поражение щитовидной, поджелудочной, половых желез, надпочечников и гипоталамо-гипофизарной системы. В их основе лежат повышенное, пониженное или полное отсутствие синтеза гормонов.</p>
                                                                    <p>
                                                                        <strong>
                                                                            Для восстановления организма при эндокринных заболеваниях
                                                                            важно:
                                                                        </strong>
                                                                    </p>
                                                                    <ul>
                                                                        <li>
                                                                            Соблюдать баланс в питании, физических и психоэмоциональных нагрузках.
                                                                        </li>
                                                                        <li>
                                                                            Восполнить дефицит макро и микроэлементов: цинка, калия, магния, селена,
                                                                            железа, молибдена, витаминов А, В, С, D, Е, полиненасыщенных жирных кислот Омега-3, качественного белка, клетчатки.
                                                                        </li>
                                                                        <li>
                                                                            Необходимо исключить из рациона копчености, соления и острые блюда, жареную, маринованную и консервированную пищу,
                                                                            острые овощи (перец чили, редька, редиска), а также кондитерские изделия, шоколад, соусные заправки и сыры, крепкий
                                                                            чай и кофе, газированные и алкогольные напитки.
                                                                        </li>
                                                                        <li>
                                                                            Рекомендовано включить в рацион черный и отрубной хлеб, гречневую, овсяную кашу, ячмень, фасоль, сою, нежирные
                                                                            сорта мяса (индейка, телятина, говядина), отварную рыбу, яйца, овощи в сыром и приготовленном виде, ягоды
                                                                            и фрукты в любом количестве, компоты из сухофруктов и кисели, травяные чаи, отвар шиповника, кисломолочные продукты, мёд.
                                                                        </li>
                                                                        <li>
                                                                            Огромное значение специалисты отводят способу приготовления блюд и рекомендуют пациентам с заболеваниями кожи
                                                                            вводить в рацион ягоды, фрукты и овощи в свежем виде, хлеб грубого помола или отрубной, яйца. При этом овощи
                                                                            подвергают минимальной термической обработке.
                                                                        </li>
                                                                    </ul>
                                                                    <p>
                                                                        <strong>Питьевой режим очень важен для процесса восстановления.</strong>
                                                                        Вода – это растворитель и транспорт для всех питательных веществ, поступающих в каждую клетку, и продуктов распада,
                                                                        выводимых из клетки. Рекомендуется употреблять только проточную фильтрованную воду, желательно температуры не ниже
                                                                        20 градусов. Черный чай, кофе, соки в объем выпитой жидкости не входит. Рекомендовано пить травяные чаи, отвары, компоты.
                                                                    </p>
                                                                    <p>
                                                                        <strong>Придерживайтесь умеренных физических нагрузок</strong>
                                                                        – ходьба классическая, ходьба скандинавская, прогулки на свежем воздухе.
                                                                    </p>
                                                                    <p>
                                                                        <strong>Отведите достаточное время на сон</strong>
                                                                        – засыпайте не позднее 23:00 и просыпайтесь не раньше 5:30. И не забудьте придерживаться рекомендаций лечащего врача.
                                                                    </p>
                                                                </v-expansion-panel-content>
                                                            </v-expansion-panel>
                                                            <v-expansion-panel>
                                                                <v-expansion-panel-header>
                                                                    Дыхательная система
                                                                </v-expansion-panel-header>
                                                                <v-expansion-panel-content>
                                                                    <p class="mt-10" style="width: 100%; max-width: 450px; height: auto">
                                                                        <img style="width: 100%" src="/img/health/breath.jpeg" alt=""/>
                                                                    </p>
                                                                    <p class="mt-10">
                                                                        <strong>Основные причины заболеваний дыхательной системы:</strong>
                                                                    </p>
                                                                    <ul>
                                                                        <li>инфекции (респираторные вирусные инфекции и патогенные микроорганизмы);</li>
                                                                        <li>любые причины, приводящие к сбоям в работе иммунной системы, и возникающие в связи с этим неадекватные реакции организма (так называемые аутоиммунные заболевания органов дыхания) на внешние аллергены, попадающие в организм с воздухом;</li>
                                                                    </ul>
                                                                    <p>К провоцирующим факторам возникновения заболеваний органов дыхания могут относиться:</p>
                                                                    <ul>
                                                                        <li>вредные привычки человека (курение и злоупотребление алкоголем);</li>
                                                                        <li>неблагоприятная экологическая обстановка и вредные производства;</li>
                                                                        <li>наличие других сопутствующих патологий (например, сахарный диабет, сердечно-сосудистые заболевания и т.д.).</li>
                                                                    </ul>
                                                                    <p>
                                                                        <strong>
                                                                            Восстановление органов и тканей дыхательной системы:
                                                                        </strong>
                                                                    </p>
                                                                    <p>Условно терапию заболеваний дыхательных путей можно разделить на два вида — лекарственную и немедикаментозную.</p>
                                                                    <p>Лекарственную терапию вам назначит врач, а мы расскажем про немедикаментозную терапию: отвары из шиповника, липы, калины, малины, настои на имбире. С разрешения лечащего врача: домашние ингаляции с различными эфирными маслами (например, с пихтовым или чайного дерева и т.д.) или экстрактами трав (календулы, ромашки, шалфея, эвкалипта и др.). При пневмонии, туберкулезе и других заболеваниях легких, протекающих в острой форме с поражением легочных тканей, показано калорийное питание (на основе натуральных животных жиров) и обильное витаминизированное питье.</p>
                                                                </v-expansion-panel-content>
                                                            </v-expansion-panel>
                                                            <v-expansion-panel>
                                                                <v-expansion-panel-header>
                                                                    Мочеполовая система
                                                                </v-expansion-panel-header>
                                                                <v-expansion-panel-content>
                                                                    <p class="mt-10" style="width: 100%; max-width: 450px; height: auto">
                                                                        <img style="width: 100%" src="/img/health/mocha.jpeg" alt=""/>
                                                                    </p>
                                                                    <p class="mt-10">
                                                                        <strong>Заболевания мочеполовой системы</strong> проявляются в расстройствах образования, отделения и испускания мочи и нарушениях половой функции. Возникают на фоне инфекционных заболеваний, переохлаждения, нарушения метаболизма, несбалансированного питания, неблагополучной экологической обстановки.
                                                                    </p>
                                                                    <p>
                                                                        Поражаются половые органы и предстательная железа, что приводит к нарушению репродуктивной функции, а также физическому дискомфорту у мужчин старше 40 лет. Выраженный дискомфорт вызывают заболевания мочевого пузыря.</p>
                                                                    <p>
                                                                        <strong>
                                                                            Для восстановления организма при заболеваниях мочеполовой системы важно:
                                                                        </strong>
                                                                    </p>
                                                                    <ul>
                                                                        <li>
                                                                            Соблюдать баланс в питании, физических и психоэмоциональных нагрузках.
                                                                        </li>
                                                                        <li>
                                                                            Восполнить дефицит макро и микроэлементов: цинка, калия, магния, селена, железа, молибдена, витаминов А, В, С, D, Е, полиненасыщенных жирных кислот Омега-3, качественного белка, клетчатки.
                                                                        </li>
                                                                        <li>
                                                                            Необходимо исключить из рациона копчености, соления и острые блюда, жареную, маринованную и консервированную пищу, крепкие бульоны, щавель, шпинат, а также кондитерские изделия, торты, пирожные,  шоколад, соусные заправки и соленые сыры, крепкий чай, кофе и какао, газированные и алкогольные напитки,  ограничивается количество соли.
                                                                        </li>
                                                                        <li>
                                                                            Рекомендовано включить в рацион бессолевой хлеб, гречневую, овсяную кашу, ячмень, сою, нежирные сорта мяса (индейка, телятина, говядина),  вегетарианские супы, отварную рыбу, яйца, овощи в сыром и приготовленном виде, ягоды (клюква, брусника), компоты из сухофруктов и кисели, травяные чаи, отвар шиповника, кисломолочные продукты, мёд.
                                                                        </li>
                                                                        <li>
                                                                            Огромное значение специалисты отводят способу приготовления блюд и рекомендуют пациентам с заболеваниями Мочеполовой системы вводить в рацион ягоды, фрукты и овощи в свежем виде, хлеб грубого помола или отрубной, яйца. При этом овощи подвергают минимальной термической обработке.  Блюда готовят без соли. Принимать пищу следует 5–6 раз в день. 
                                                                        </li>
                                                                    </ul>
                                                                    <p>
                                                                        <strong>Питьевой режим очень важен для процесса восстановления.</strong>
                                                                        Вода – это растворитель и транспорт для всех питательных веществ, поступающих в каждую клетку, и продуктов распада, выводимых из клетки. Рекомендуется употреблять только проточную фильтрованную воду, желательно температуры не ниже 20 градусов. Черный чай, кофе, соки в объем выпитой жидкости не входит. Рекомендовано пить травяные чаи, отвары, компоты.  Водный режим определяется индивидуально.
                                                                    </p>
                                                                    <p>
                                                                        <strong>Придерживайтесь умеренных физических нагрузок</strong>
                                                                        – ходьба классическая, ходьба скандинавская, прогулки на свежем воздухе.
                                                                    </p>
                                                                    <p>
                                                                        <strong>Отведите достаточное время на сон</strong>
                                                                        – засыпайте не позднее 23:00 и просыпайтесь не раньше 5:30. И не забудьте придерживаться рекомендаций лечащего врача.
                                                                    </p>
                                                                </v-expansion-panel-content>
                                                            </v-expansion-panel>
                                                            <v-expansion-panel>
                                                                <v-expansion-panel-header>
                                                                    Кожа
                                                                </v-expansion-panel-header>
                                                                <v-expansion-panel-content>
                                                                    <p class="mt-10" style="width: 100%; max-width: 450px; height: auto">
                                                                        <img style="width: 100%" src="/img/health/skin.jpeg" alt=""/>
                                                                    </p>
                                                                    <p class="mt-10">
                                                                        Кожа является самым большим органом человека, ее основные функции - защитная и выделительная. Патологии кожи можно разделить на инфекционные и неинфекционные.
                                                                    </p>
                                                                    <p>
                                                                        Очень часто человек неосознанно создает условия для возникновения заболеваний кожи. К примеру, многие любят купаться ежедневно, при этом активно применяют моющие средства, отмывая кожу «до скрипа». Как ни странно, именно у этих людей развиваются грибковые и гнойничковые заболевания кожи. Связано это с тем, что в норме на поверхности кожи должен быть тонкий слой кожного сала, который несет защитную функцию, а его регулярное удаление «открывает» входные ворота для инфекции.
                                                                    </p>
                                                                    <p>
                                                                        <strong>
                                                                            Для восстановления организма при заболеваниях кожи важно:
                                                                        </strong>
                                                                    </p>
                                                                    <ul>
                                                                        <li>
                                                                            Соблюдать баланс в питании, физических и психоэмоциональных нагрузках.
                                                                        </li>
                                                                        <li>
                                                                            Восполнить дефицит макро и микроэлементов: цинка, калия, магния, селена,
                                                                            железа, молибдена, витаминов А, В, С, D, Е, полиненасыщенных жирных кислот Омега-3, качественного белка, клетчатки.
                                                                            <br><br>
                                                                            <strong>Огромную роль в процессе восстановления организма играет состояние кишечника, а именно микрофлора толстой кишки</strong>
                                                                        </li>
                                                                        <li>
                                                                            Необходимо исключить из рациона копчености, соления и острые блюда, жареную, маринованную и консервированную пищу,
                                                                            острые овощи (перец чили, редька, редиска), а также кондитерские изделия, шоколад, соусные заправки и сыры, крепкий
                                                                            чай и кофе, газированные и алкогольные напитки.
                                                                        </li>
                                                                        <li>
                                                                            Рекомендовано включить в рацион черный и отрубной хлеб, гречневую, овсяную кашу, ячмень, фасоль, сою, нежирные
                                                                            сорта мяса (индейка, телятина, говядина), отварную рыбу, яйца, овощи в сыром и приготовленном виде, ягоды
                                                                            и фрукты в любом количестве, компоты из сухофруктов и кисели, травяные чаи, отвар шиповника, кисломолочные продукты, мёд.
                                                                        </li>
                                                                        <li>
                                                                            Огромное значение специалисты отводят способу приготовления блюд и рекомендуют пациентам с заболеваниями кожи
                                                                            вводить в рацион ягоды, фрукты и овощи в свежем виде, хлеб грубого помола или отрубной, яйца. При этом овощи
                                                                            подвергают минимальной термической обработке.
                                                                        </li>
                                                                    </ul>
                                                                    <p>
                                                                        <strong>Питьевой режим очень важен для процесса восстановления.</strong>
                                                                        Вода – это растворитель и транспорт для всех питательных веществ, поступающих в каждую клетку, и продуктов распада,
                                                                        выводимых из клетки. Рекомендуется употреблять только проточную фильтрованную воду, желательно температуры не ниже
                                                                        20 градусов. Черный чай, кофе, соки в объем выпитой жидкости не входит. Рекомендовано пить травяные чаи, отвары, компоты.
                                                                    </p>
                                                                    <p>
                                                                        <strong>Придерживайтесь умеренных физических нагрузок</strong>
                                                                        – ходьба классическая, ходьба скандинавская, прогулки на свежем воздухе.
                                                                    </p>
                                                                    <p>
                                                                        <strong>Отведите достаточное время на сон</strong>
                                                                        – засыпайте не позднее 23:00 и просыпайтесь не раньше 5:30. И не забудьте придерживаться рекомендаций лечащего врача.
                                                                    </p>
                                                                </v-expansion-panel-content>
                                                            </v-expansion-panel>
                                                        </v-expansion-panels>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        v-if="btnDalee2"
                                                        class="chat-controls"
                                                    >
                                                        <v-btn
                                                            class="chat-btn"
                                                            :disabled="disBtnReq2"
                                                            @click="btnReq2"
                                                        >
                                                            <v-icon class="mr-3">mdi-send</v-icon>
                                                            Далее
                                                        </v-btn>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesReq4"
                                                    >
                                                        <div class="chat-avatar">
                                                            <img v-if="userData.avatar" :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"
                                                                 alt=""
                                                            >
                                                        </div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime3 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p class="mb-5">
                                                                        <strong>
                                                                            Огромную роль в процессе восстановления организма играет состояние кишечника, а именно микрофлора толстой кишки
                                                                        </strong>
                                                                    </p>
                                                                    <p class="mb-5">
                                                                        Как получать все необходимые нутриенты для здоровья нашего организма каждый день?
                                                                    </p>
                                                                    <p class="mb-5">В наше время получить из пищи необходимую ежедневную норму БЖУ (белки, жиры, углеводы), витаминов, минералов и других нутриентов, необходимых для здорового развития нашего организма, практически невозможно.</p>
                                                                    <p class="mb-5">И как следствие, это приводит к различным заболеваниям и напрямую связан с продолжительностью жизни.</p>
                                                                    <p class="mb-5">Из продуктов питания мы получаем только около 5% необходимых нутриентов, то есть мы живём в условиях 95% дефицита.</p>
                                                                    <p class="mb-5"><strong>Как же восполнить этот дефицит?</strong></p>
                                                                    <p class="mb-5">Необходимо употреблять биологически активные добавки, как это делают в развитых странах Европы, США и Японии вот уже много лет.</p>
                                                                    <p class="mb-5">Для примера, сравните зависимость между средней продолжительностью жизни и процентом людей, потребляющих БАДы в разных странах</p>
                                                                    <p class="mb-5" style="width: 100%; max-width: 450px; height: auto">
                                                                        <img src="/img/leedbot/grafik_bad.jpeg" style="width: 100%" alt=""/>
                                                                    </p>
                                                                    <p class="mb-5">Как видно из графика, жители стран, потребляющих БАДы, живут значительно дольше.</p>
                                                                    <p class="mb-5">Специально для вас мы подготовили короткое экспертное видео-интервью с Заведующим кафедрой биохимии Санкт-Петербургской медицинской академии им. И.И. Мечникова, Доктором химических наук, Профессором, членом общества нутрициологии США, членом наблюдательного совета Института Питания РАМН — Владимиром Дадали.</p>
                                                                    <div class="thumb-wrap mt-13 mb-10">
                                                                      <iframe width="560" height="315" src="https://www.youtube.com/embed/rQ-TlIVgXWo?si=nyYvdO01tvmrXuAy" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        v-if="btnDalee3"
                                                        class="chat-controls"
                                                    >
                                                        <v-btn
                                                            class="chat-btn"
                                                            :disabled="disBtnReq3"
                                                            @click="btnReq3"
                                                        >
                                                            <v-icon class="mr-3">mdi-send</v-icon>
                                                            Далее
                                                        </v-btn>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesReq5"
                                                    >
                                                        <div class="chat-avatar">
                                                            <img v-if="userData.avatar" :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"
                                                                 alt=""
                                                            >
                                                        </div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime4 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p class="mb-5">
                                                                        Какие добавки выбрать?
                                                                    </p>
                                                                    <p class="mb-5">
                                                                        Если вы хотите жить дольше и при этом прекрасно себя чувствовать, хотите здоровую семью, здоровых родителей, здоровых детей, то употребление БАДов в текущей реальности - это не роскошь, а необходимость.
                                                                    </p>
                                                                    <p class="mb-5">Но не все БАДы одинаковы!</p>
                                                                    <p class="mb-5"><strong>Важно выбрать качественные добавки от проверенного производителя.</strong></p>
                                                                    <p class="mb-5">Проанализировав рынок для себя мы выбрали действительно качественные добавки от надежного производителя.</p>
                                                                    <p class="mb-5">Далее мы хотим познакомить вас с немецкой компанией-производителем
                                                                        LR Health & Beauty, основанной в Германии в 1985 году.</p>
                                                                    <p class="mb-5">Миссия компании - More quality for your life (Больше качества вашей жизни)</p>
                                                                    <ul>
                                                                        <li class="mb-5">LR Health & Beauty - один из крупнейших производителей алое вера в мире</li>
                                                                        <li class="mb-5">Самая <strong>современная производственная площадка алое вера в Европе</strong></li>
                                                                        <li class="mb-5">У компании свои исследовательские центры, лаборатории и научный совет</li>
                                                                        <li class="mb-5">На производстве LR действует система постоянного внутреннего контроля качества, а также проводятся строгие проверки и сертификация продукции авторитетными независимыми организациями.</li>
                                                                        <li class="mb-5">Широкий ассортимент высококачественных натуральных продуктов, разработанных на основе натуральных природных компонентов в сочетании с новейшими достижениями науки</li>
                                                                        <li class="mb-5"><strong>19 запатентованных продуктов.</strong></li>
                                                                    </ul>
                                                                    <p class="mb-5">Посмотрите видео о компании LR Health & Beauty</p>
                                                                    <div class="thumb-wrap mt-13 mb-10">
                                                                        <iframe
                                                                            width="560"
                                                                            height="315"
                                                                            src="https://www.youtube.com/embed/VzjFiA29GcI"
                                                                            title="О компании LR"
                                                                            frameborder="0"
                                                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                                            allowfullscreen
                                                                        ></iframe>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        v-if="btnDalee4"
                                                        class="chat-controls"
                                                    >
                                                        <v-btn
                                                            class="chat-btn"
                                                            :disabled="disBtnReq4"
                                                            @click="btnReq4"
                                                        >
                                                            <v-icon class="mr-3">mdi-send</v-icon>
                                                            Далее
                                                        </v-btn>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesReq6"
                                                    >
                                                        <div class="chat-avatar">
                                                            <img v-if="userData.avatar" :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"
                                                                 alt=""
                                                            >
                                                        </div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime5 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p class="mb-5">
                                                                        В основе большинства продуктов компании LR используется Алое Вера Барбаденсис Миллер.
                                                                    </p>
                                                                    <p class="mb-5">
                                                                        Флагманский продукт - это <strong>серия питьевых гелей Алое Вера</strong>. Для их производства используются растения, выращенные в высокогорьях Мексики, где для этого имеются самые лучшие условия, позволяющие не использовать пестициды.
                                                                    </p>
                                                                    <p class="mb-5">
                                                                        В составе алое вера более 150 биологически активных веществ, определяющих его уникальность и целебный свойства.
                                                                    </p>
                                                                    <p class="mb-5">
                                                                        <strong>Гель Алоэ Вера улучшает снабжение клеток питательными веществами, а также выводит из клеток шлаки</strong>. Тем самым ускоряет реакции обмена и оказывает положительное действие на организм.
                                                                    </p>
                                                                    <p class="mb-5">
                                                                        В категории "производители напитков на основе алое вера", только компания LR <strong>имеет знак качества института Fresenius. Это высочайших стандарт в Европе</strong>.
                                                                    </p>
                                                                    <p class="mb-5">
                                                                        <strong>Собственное производство</strong> LR располагается в г. Ален (Германия). На современном заводе, соответствующем европейским стандартам сертификации производственных предприятий, выпускается 90% всей продукции LR.
                                                                    </p>
                                                                    <p class="mb-5">Посмотрите видео про Алое Вера</p>
                                                                    <div class="thumb-wrap mt-13 mb-10">
                                                                        <iframe
                                                                            width="560"
                                                                            height="315"
                                                                            src="https://www.youtube.com/embed/6y6JxGx0xi0"
                                                                            title="Про Алое Вера от компании LR"
                                                                            frameborder="0"
                                                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                                            allowfullscreen
                                                                        ></iframe>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        v-if="btnDalee5"
                                                        class="chat-controls"
                                                    >
                                                        <v-btn
                                                            class="chat-btn"
                                                            :disabled="disBtnReq5"
                                                            @click="btnReq5"
                                                        >
                                                            <v-icon class="mr-3">mdi-send</v-icon>
                                                            Далее
                                                        </v-btn>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesReq7"
                                                    >
                                                        <div class="chat-avatar">
                                                            <img v-if="userData.avatar" :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"
                                                                 alt=""
                                                            >
                                                        </div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime6 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p class="mb-5">
                                                                        Немного результатов от клиентов компании LR
                                                                    </p>
                                                                    <p
                                                                        class="mb-5"
                                                                        style="width: 100%; max-width: 550px; height: auto;"
                                                                    >
                                                                        <img style="width: 100%;" src="/img/leedbot/product/product1.jpg" alt=""/>
                                                                    </p>

                                                                    <transition name="fade">
                                                                        <p
                                                                            v-if="imgProduct2"
                                                                            class="mb-5"
                                                                            style="width: 100%; max-width: 550px; height: auto;"
                                                                        >
                                                                            <img style="width: 100%;" src="/img/leedbot/product/product2.jpg" alt=""/>
                                                                        </p>
                                                                    </transition>

                                                                    <transition name="fade">
                                                                        <div
                                                                            v-if="videoProduct3"
                                                                            class="mb-5"
                                                                        >
                                                                            <div class="thumb-wrap">
                                                                                <iframe
                                                                                    width="560"
                                                                                    height="315"
                                                                                    src="https://www.youtube.com/embed/ZDBu0KPh2fk"
                                                                                    title="Отзыв о продукции LR (3)"
                                                                                    frameborder="0"
                                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                                                    allowfullscreen
                                                                                ></iframe>
                                                                            </div>
                                                                        </div>
                                                                    </transition>

                                                                    <transition name="fade">
                                                                        <div
                                                                            v-if="videoProduct4"
                                                                            class="mb-5"
                                                                        >
                                                                            <div class="thumb-wrap">
                                                                                <iframe
                                                                                    width="560"
                                                                                    height="315"
                                                                                    src="https://www.youtube.com/embed/rUBohM478XY"
                                                                                    title="Отзыв о продукции LR (4)"
                                                                                    frameborder="0"
                                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                                                    allowfullscreen
                                                                                ></iframe>
                                                                            </div>
                                                                        </div>
                                                                    </transition>

                                                                    <transition name="fade">
                                                                        <div
                                                                            v-if="videoProduct5"
                                                                            class="mb-5"
                                                                        >
                                                                            <div class="thumb-wrap">
                                                                                <iframe
                                                                                    width="560"
                                                                                    height="315"
                                                                                    src="https://www.youtube.com/embed/zKMMQGILR5g"
                                                                                    title="Отзыв о продукции LR (5)"
                                                                                    frameborder="0"
                                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                                                    allowfullscreen
                                                                                ></iframe>
                                                                            </div>
                                                                        </div>
                                                                    </transition>

                                                                    <transition name="fade">
                                                                        <div
                                                                            v-if="videoProduct6"
                                                                            class="mb-5"
                                                                        >
                                                                            <div class="thumb-wrap">
                                                                                <iframe
                                                                                    width="560"
                                                                                    height="315"
                                                                                    src="https://www.youtube.com/embed/dwv4gwHiNto"
                                                                                    title="Отзыв о продукции LR (6)"
                                                                                    frameborder="0"
                                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                                                    allowfullscreen
                                                                                ></iframe>
                                                                            </div>
                                                                        </div>
                                                                    </transition>

                                                                    <transition name="fade">
                                                                        <div
                                                                            v-if="videoProduct7"
                                                                            class="mb-5"
                                                                        >
                                                                            <div class="thumb-wrap">
                                                                                <iframe
                                                                                    width="560"
                                                                                    height="315"
                                                                                    src="https://www.youtube.com/embed/KidZ_iXUnfs"
                                                                                    title="Отзыв о продукции LR (7)"
                                                                                    frameborder="0"
                                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                                                    allowfullscreen
                                                                                ></iframe>
                                                                            </div>
                                                                        </div>
                                                                    </transition>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        v-if="btnDalee6"
                                                        class="chat-controls"
                                                    >
                                                        <v-btn
                                                            class="chat-btn"
                                                            :disabled="disBtnReq6"
                                                            @click="btnReq6"
                                                        >
                                                            <v-icon class="mr-3">mdi-send</v-icon>
                                                            Далее
                                                        </v-btn>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesReqEnd"
                                                    >
                                                        <div class="chat-avatar">
                                                            <img v-if="userData.avatar" :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"
                                                                 alt=""
                                                            >
                                                        </div>
                                                        <div class="chat-block" v-if="userData.id != 127">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime7 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p class="mb-5">
                                                                        Хотите получить бесплатную консультацию по продукции LR?
                                                                    </p>
                                                                    <p class="mb-3 mr-3">
                                                                        Свяжитесь со мной:
                                                                    </p>
                                                                    <p>
                                                                        <a
                                                                            v-if="userData.phone_whatsapp"
                                                                            style="text-decoration: none"
                                                                            :href="`https://wa.me/${userData.phone_whatsapp}`"
                                                                            target="_blank"
                                                                        >
                                                                            <v-btn
                                                                                fab
                                                                                dark
                                                                                small
                                                                                class="mr-3"
                                                                                color="success"
                                                                            >
                                                                                <v-icon>mdi-whatsapp</v-icon>
                                                                            </v-btn>
                                                                        </a>
                                                                        <a
                                                                            v-if="userData.phone_viber"
                                                                            style="text-decoration: none"
                                                                            :href="`viber://chat?number=${userData.phone_viber}`"
                                                                            target="_blank"
                                                                        >
                                                                            <v-btn
                                                                                fab
                                                                                dark
                                                                                small
                                                                                class="mr-3"
                                                                                color="deep-purple lighten-2"
                                                                            >
                                                                                <v-icon>fab fa-viber</v-icon>
                                                                            </v-btn>
                                                                        </a>
                                                                        <a
                                                                            v-if="userData.telegram"
                                                                            style="text-decoration: none"
                                                                            :href="`${userData.telegram}`"
                                                                            target="_blank"
                                                                        >
                                                                            <v-btn
                                                                                fab
                                                                                dark
                                                                                small
                                                                                class="mr-3"
                                                                                color="blue"
                                                                            >
                                                                                <v-icon>mdi-send</v-icon>
                                                                            </v-btn>
                                                                        </a>
                                                                        <a
                                                                            v-if="userData.fb_messenger"
                                                                            :href="`${userData.fb_messenger}`"
                                                                            target="_blank"
                                                                            style="text-decoration: none"
                                                                        >
                                                                            <v-btn
                                                                                fab
                                                                                dark
                                                                                small
                                                                                class="mr-3"
                                                                                color="light-blue"
                                                                            >
                                                                                <v-icon>mdi-facebook-messenger</v-icon>
                                                                            </v-btn>
                                                                        </a>
                                                                        <a
                                                                            v-if="userData.vkontakte"
                                                                            :href="`${userData.vkontakte}`"
                                                                            target="_blank"
                                                                            style="text-decoration: none"
                                                                        >
                                                                            <v-btn
                                                                                fab
                                                                                dark
                                                                                small
                                                                                class="mr-4"
                                                                                color="blue darken-2"
                                                                            >
                                                                                <v-icon>fab fa-vk</v-icon>
                                                                            </v-btn>
                                                                        </a>
                                                                        <a
                                                                            v-if="userData.odnoklassniki"
                                                                            :href="`${userData.odnoklassniki}`"
                                                                            target="_blank"
                                                                            style="text-decoration: none"
                                                                        >
                                                                            <v-btn
                                                                                fab
                                                                                dark
                                                                                small
                                                                                class="mr-4"
                                                                                color="orange darken-2"
                                                                            >
                                                                                <v-icon>mdi-odnoklassniki</v-icon>
                                                                            </v-btn>
                                                                        </a>
                                                                        <a
                                                                            v-if="userData.instagram"
                                                                            :href="`${userData.instagram}`"
                                                                            target="_blank"
                                                                            style="text-decoration: none"
                                                                        >
                                                                            <v-btn
                                                                                fab
                                                                                dark
                                                                                small
                                                                                class="mr-4"
                                                                                color="pink darken-1"
                                                                            >
                                                                                <v-icon>mdi-instagram</v-icon>
                                                                            </v-btn>
                                                                        </a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>
                                            </div>
                                            <div
                                                class="chat-typing"
                                                v-bind:class="{show: isShow}"
                                            >
                                                {{ userData.name }} печатает
                                                <div class="chat-typing-animate">
                                                    <div class="chat-typing-box">
                                                        ...
                                                        <img src="/img/leedbot/edit.svg" alt="">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="scrollToMe"></div>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </v-row>
        </section>
    </v-main>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
    name: "HealthReq",
    data: () => ({
        isShow: false,
        loading: true,
        lr_number: '',
        inst: '',
        errors: {},
        // playerOptions1: {
        //     muted: false,
        //     playbackRates: [0.7, 1.0, 1.5, 2.0],
        //     sources: [{
        //         type: "video/mp4",
        //         src: "/video/10_rules.mp4"
        //     }],
        //     poster: "",
        // },
        // playerOptions2: {
        //     muted: false,
        //     playbackRates: [0.7, 1.0, 1.5, 2.0],
        //     sources: [{
        //         type: "video/mp4",
        //         src: "/video/leedbot/dadali_lr.mp4"
        //     }],
        //     poster: "",
        // },
        // playerOptions3: {
        //     muted: false,
        //     playbackRates: [0.7, 1.0, 1.5, 2.0],
        //     sources: [{
        //         type: "video/mp4",
        //         src: "/video/leedbot/about_lr.mp4"
        //     }],
        //     poster: "",
        // },
        // playerOptions4: {
        //     muted: false,
        //     playbackRates: [0.7, 1.0, 1.5, 2.0],
        //     sources: [{
        //         type: "video/mp4",
        //         src: "/video/leedbot/aloe_vera_lr.mp4"
        //     }],
        //     poster: "",
        // },
        // playerOptions5: {
        //     muted: false,
        //     playbackRates: [0.7, 1.0, 1.5, 2.0],
        //     sources: [{
        //         type: "video/mp4",
        //         src: "/video/leedbot/product/videoProduct3.mp4"
        //     }],
        //     poster: "",
        // },
        // playerOptions6: {
        //     muted: false,
        //     playbackRates: [0.7, 1.0, 1.5, 2.0],
        //     sources: [{
        //         type: "video/mp4",
        //         src: "/video/leedbot/product/videoProduct4.mp4"
        //     }],
        //     poster: "",
        // },
        // playerOptions7: {
        //     muted: false,
        //     playbackRates: [0.7, 1.0, 1.5, 2.0],
        //     sources: [{
        //         type: "video/mp4",
        //         src: "/video/leedbot/product/videoProduct5.mp4"
        //     }],
        //     poster: "",
        // },
        // playerOptions8: {
        //     muted: false,
        //     playbackRates: [0.7, 1.0, 1.5, 2.0],
        //     sources: [{
        //         type: "video/mp4",
        //         src: "/video/leedbot/product/videoProduct6.mp4"
        //     }],
        //     poster: "",
        // },
        // playerOptions9: {
        //     muted: false,
        //     playbackRates: [0.7, 1.0, 1.5, 2.0],
        //     sources: [{
        //         type: "video/mp4",
        //         src: "/video/leedbot/product/videoProduct7.mp4"
        //     }],
        //     poster: "",
        // },
        nowDateTime: {
            dateTime1: '',
            dateTime2: '',
            dateTime3: '',
            dateTime4: '',
            dateTime5: '',
            dateTime6: '',
            dateTime7: '',
        },
        cookieShow: true,
        mesFirst: false,
        mesSecond: false,
        mesReq1: false,
        btnDalee1: false,
        disBtnReq1: false,
        mesReq2: false,
        mesReq3: false,
        btnDalee2: false,
        disBtnReq2: false,
        mesReq4: false,
        btnDalee3: false,
        disBtnReq3: false,
        mesReq5: false,
        btnDalee4: false,
        disBtnReq4: false,
        mesReq6: false,
        btnDalee5: false,
        disBtnReq5: false,
        mesReq7: false,
        imgProduct2: false,
        videoProduct3: false,
        videoProduct4: false,
        videoProduct5: false,
        videoProduct6: false,
        videoProduct7: false,
        btnDalee6: false,
        disBtnReq6: false,
        mesReqEnd: false,
    }),
    metaInfo: {
        title: 'Рекомендации по питанию и укреплению здоровья от LR Health & Beauty',
        meta: [
            { vmid: 'description', property: 'description', content: 'Рекомендации по питанию и укреплению здоровья от LR Health & Beauty' },
            { vmid: 'og:title', property: 'og:title', content: 'Рекомендации по питанию и укреплению здоровья от LR Health & Beauty' },
            { vmid: 'og:description', property: 'og:description', content: 'Рекомендации по питанию и укреплению здоровья от LR Health & Beauty' },
            { vmid: 'og:image', property: 'og:image', content: '/img/newlvl_logo.png' },
        ],
    },
    created() {
        if (!this.$route.query.partner) {
            if (this.$cookies.isKey('newlvl_partner')) {
                const partnerNum = this.$cookies.get('newlvl_partner')
                this.$router.push ({name: 'HealthReq', query: { inst: 'test', partner: partnerNum }})
            }
        } else {
            this.$cookies.set('newlvl_partner', this.$route.query.partner, '6m')
        }

        if (this.$cookies.isKey('newlvl_cookie')) {
            this.cookieShow = false
        }
        this.lr_number = this.$route.query.partner
        this.inst = this.$route.query.inst
    },
    computed: {
        ...mapGetters({
            userData: 'user/getUserHealthTest',
        }),
    },
    methods: {
        ...mapActions({
            showUser: 'user/showUserTest',
        }),
        zeroFirst(value) {
            if (value < 10) {
                value='0'+value
            }
            return value
        },
        dateTimeNow() {
            let currentTime = new Date()
            let day = this.zeroFirst(currentTime.getDate())
            let month = this.zeroFirst(currentTime.getMonth()+1)
            let hours = this.zeroFirst(currentTime.getHours())
            let minutes = this.zeroFirst(currentTime.getMinutes())

            if (month === '01') {
                month = 'января'
            }
            if (month === '02') {
                month = 'февраля'
            }
            if (month === '03') {
                month = 'марта'
            }
            if (month === '04') {
                month = 'апреля'
            }
            if (month === '05') {
                month = 'мая'
            }
            if (month === '06') {
                month = 'июня'
            }
            if (month === '07') {
                month = 'июля'
            }
            if (month === '08') {
                month = 'августа'
            }
            if (month === '09') {
                month = 'сентября'
            }
            if (month === '10') {
                month = 'октября'
            }
            if (month === '11') {
                month = 'ноября'
            }
            if (month === '12') {
                month = 'декабря'
            }

            return day+' '+month+',  '+hours+':'+minutes
        },
        cookieAccept() {
            this.cookieShow = false
            this.$cookies.set('newlvl_cookie', 'accept', '12m')
        },
        scrollToMe () {
            let scrollBottom = document.getElementById("scrollToMe")
            scrollBottom.scrollIntoView({ behavior: "smooth" })
        },
        showTyping() {
            let v = this
            setTimeout(function () {
                v.isShow = true
            }, 1000)
            this.showFirstMessage()
        },
        showTypingTxt() {
            let v = this
            setTimeout(function () {
                v.isShow = true
            }, 1000)
        },
        showFirstMessage() {
            let v = this
            this.nowDateTime.dateTime1 = this.dateTimeNow()
            setTimeout(function () {
                v.isShow = false
            }, 3000)
            setTimeout(function () {
                v.mesFirst = true
            }, 3300)
            this.showSecondMessage()
        },
        showSecondMessage() {
            let v = this
            setTimeout(function () {
                v.isShow = true
            }, 4300)
            setTimeout(function () {
                v.isShow = false
            }, 6300)
            setTimeout(function () {
                v.mesSecond = true
            }, 6600)
            this.showMesReq1()
        },
        showMesReq1() {
            let v = this
            setTimeout(function () {
                v.isShow = true
            }, 7600)
            setTimeout(function () {
                v.isShow = false
            }, 9600)
            setTimeout(function () {
                v.mesReq1 = true
            }, 10000)
            setTimeout(function () {
                v.btnDalee1 = true
            }, 20000)
        },
        btnReq1() {
            this.nowDateTime.dateTime2 = this.dateTimeNow()
            this.disBtnReq1 = true
            this.showTypingTxt()
            this.showMesReq2()
        },
        showMesReq2() {
            let v = this
            setTimeout(function () {
                v.isShow = false
            }, 3000)
            setTimeout(function () {
                v.mesReq2 = true
            }, 3500)
            setTimeout(function () {
                v.mesReq3 = true
            }, 6500)
            setTimeout(function () {
                v.btnDalee2 = true
            }, 7000)
        },
        btnReq2() {
            this.nowDateTime.dateTime3 = this.dateTimeNow()
            this.disBtnReq2 = true
            this.showTypingTxt()
            this.showMesReq4()
        },
        showMesReq4() {
            let v = this
            setTimeout(function () {
                v.isShow = false
            }, 3000)
            setTimeout(function () {
                v.mesReq4 = true
            }, 3500)
            setTimeout(function () {
                v.btnDalee3 = true
            }, 3500)
        },
        btnReq3() {
            this.nowDateTime.dateTime4 = this.dateTimeNow()
            this.disBtnReq3 = true
            this.showTypingTxt()
            this.showMesReq5()
        },
        showMesReq5() {
            let v = this
            setTimeout(function () {
                v.isShow = false
            }, 3000)
            setTimeout(function () {
                v.mesReq5 = true
            }, 3500)
            setTimeout(function () {
                v.btnDalee4 = true
            }, 3500)
        },
        btnReq4() {
            this.nowDateTime.dateTime5 = this.dateTimeNow()
            this.disBtnReq4 = true
            this.showTypingTxt()
            this.showMesReq6()
        },
        showMesReq6() {
            let v = this
            setTimeout(function () {
                v.isShow = false
            }, 3000)
            setTimeout(function () {
                v.mesReq6 = true
            }, 3500)
            setTimeout(function () {
                v.btnDalee5 = true
            }, 3500)
        },
        btnReq5() {
            this.nowDateTime.dateTime6 = this.dateTimeNow()
            this.disBtnReq5 = true
            this.showTypingTxt()
            this.showMesReq7()
        },
        showMesReq7() {
            let v = this
            setTimeout(function () {
                v.isShow = false
            }, 3000)
            setTimeout(function () {
                v.mesReq7 = true
            }, 3500)
            setTimeout(function () {
                v.isShow = true
            }, 4500)
            setTimeout(function () {
                v.isShow = false
            }, 6500)
            setTimeout(function () {
                v.imgProduct2 = true
            }, 7000)
            setTimeout(function () {
                v.isShow = true
            }, 12500)
            setTimeout(function () {
                v.isShow = false
            }, 14500)
            setTimeout(function () {
                v.videoProduct3 = true
            }, 15500)
            setTimeout(function () {
                v.isShow = true
            }, 16500)
            setTimeout(function () {
                v.isShow = false
            }, 18500)
            setTimeout(function () {
                v.videoProduct4 = true
            }, 19000)
            setTimeout(function () {
                v.isShow = true
            }, 20000)
            setTimeout(function () {
                v.isShow = false
            }, 22000)
            setTimeout(function () {
                v.videoProduct5 = true
            }, 22500)
            setTimeout(function () {
                v.isShow = true
            }, 23500)
            setTimeout(function () {
                v.isShow = false
            }, 25500)
            setTimeout(function () {
                v.videoProduct6 = true
            }, 26000)
            setTimeout(function () {
                v.isShow = true
            }, 27000)
            setTimeout(function () {
                v.isShow = false
            }, 29000)
            setTimeout(function () {
                v.videoProduct7 = true
            }, 29500)
            setTimeout(function () {
                v.btnDalee6 = true
            }, 40000)
        },
        btnReq6() {
            this.nowDateTime.dateTime7 = this.dateTimeNow()
            this.disBtnReq6 = true
            this.showTypingTxt()
            this.showMesReqEnd()
        },
        showMesReqEnd() {
            let v = this
            setTimeout(function () {
                v.isShow = false
            }, 3000)
            setTimeout(function () {
                v.mesReqEnd = true
            }, 3500)
        },
    },
    mounted() {
        this.showUser(this.lr_number)
        this.loading = false

        this.showTyping()

        this.clientData.partner = this.$route.query.partner
        this.clientData.inst = this.$route.query.inst
    },
    updated() {
        this.scrollToMe()
    },
}
</script>

<style lang="sass">
.chat-list p
    font-size: 16px

.chat-list
    width: 100%
    height: 100%
    box-sizing: border-box
    display: flex
    flex-direction: column
    justify-content: flex-end
    padding: 40px 11px

.chat-item
    display: flex
    margin-bottom: 15px

.chat-item.item-user
    width: 100%
    align-self: flex-end
    flex-direction: row-reverse
    margin-top: 40px
    margin-bottom: 40px

.chat-item p
    margin-bottom: 0

.exp-panel-title .v-expansion-panel-header
    font-size: 16px

.exp-panel-title .v-expansion-panel--active
    border: 1px solid #69db69

.exp-panel-title ul li
    font-size: 16px
    padding-bottom: 10px

.chat-item ul
    list-style: none
    padding: 0
    font-size: 16px

.chat-item ul li i
    top: -2px

.chat-avatar
    width: 32px
    height: 32px
    margin-top: 20px
    margin-right: 10px
    background-size: cover
    background-position: center
    background-repeat: no-repeat
    background-color: #999999
    flex-shrink: 0
    border-radius: 50%

.chat-avatar img
    width: 100%
    border-radius: 50%

.chat-block
    min-width: 60%
    max-width: 90%

.chat-date
    opacity: 0.5
    color: #252525
    font-size: 14px
    font-weight: 400
    margin-bottom: 5px

.chat-messages
    width: 100%
    display: flex
    flex-direction: column
    align-items: flex-start

.chat-message
    display: inline-block
    background-color: rgb(248, 248, 248)
    padding: 15px 20px
    color: rgb(37, 37, 37)
    font-size: 14px
    font-weight: 400
    line-height: 1.5
    margin-bottom: 5px
    min-width: 250px

.chat-message:last-of-type
    border-bottom-left-radius: 10px
    border-bottom-right-radius: 10px

.chat-message:first-of-type
    border-top-left-radius: 10px
    border-top-right-radius: 10px

.chat-input
    margin-bottom: 10px

.chat-controls
    display: flex
    margin-top: 10px
    align-items: center
    flex-wrap: wrap

.chat-controls-end
    display: flex
    margin-top: 10px
    align-items: center
    flex-wrap: wrap
    justify-content: flex-start

.chat-btn
    margin-bottom: 10px !important
    background-color: #4caf50 !important

.chat-btn.btn-skip
    background-color: gray !important

.chat-btn.btn-send
    margin-left: auto

.item-user .chat-avatar-user
    background-image: url("/img/leedbot/user_avatar.png")
    margin-left: 10px
    margin-right: 0
    background-size: cover
    background-position: center
    background-repeat: no-repeat
    background-color: #999999
    flex-shrink: 0
    border-radius: 50%
    width: 32px
    height: 32px
    margin-top: 20px

.item-user .chat-date
    text-align: right

.item-user .chat-messages
    align-items: flex-end

.chat-typing
    font-size: 12px
    color: #999999
    display: flex
    align-items: baseline
    margin-left: 45px
    opacity: 0
    transform: translateY(5px)

.chat-typing.show
    opacity: 1
    transform: translateY(0)
    transition: 0.5s

.chat-typing-animate
    width: 27px
    overflow: hidden
    margin-left: 5px

.chat-typing-box
    display: flex

@keyframes typing
    0%
        transform: translateX(-30%)
    100%
        transform: translateX(0px)

@-webkit-keyframes typing
    0%
        transform: translateX(-30%)
    100%
        transform: translateX(0px)

.chat-typing.show .chat-typing-box
    -webkit-animation: typing 1s infinite
    animation: typing 1s infinite

.chat-typing-box img
    width: 12px
    height: 12px

.fade-enter-active, .fade-leave-active
    transition: opacity 1s

.fade-enter, .fade-leave-to
    opacity: 0

@media(max-width: 600px)
    .main-col
        padding-left: 0
        padding-right: 0

    .chat-list
        padding-left: 0
        padding-right: 0

</style>